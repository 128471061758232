<template>
  <div class="certWrap">
    <h1 class="chinese chBig">龙腾之星</h1>
    <h1 class="english enBig">D R A G O N <span class="space"></span> S T A R</h1>
    <h2 class="chinese chSmall">全球大学生绿色设计大赛</h2>
    <h2 class="english enSmall">GLOBAL COLLEGE STUDENTS GREEN DESIGN CONTEST</h2>
    <template v-if="step==1">
      <h1 class="english chSmall chShadow">优秀组织奖</h1>
      <h1 class="english enShadow">EXCELLENT ORGANIZATION AWARD</h1>
      <h1 class="english chBig">查 询</h1>
      <h1 class="english query">Query</h1>
      <ul class="formNote">
        <li class="item"><img src="/img/arrow.png" alt=""></li>
        <li class="item">
          <p>请输入查询条件</p>
          <p class="en">Please type in your query</p>  
        </li>
        <li class="item"><img src="/img/arrow.png" alt=""></li>
      </ul>
      <ul>
        <li class="label">
          <p class="ch">院校名称：</p>
          <p class="en">Name of University</p>
        </li>
        <li class="input"><input type="text" v-model="form.school" id="school"></li>
      </ul>
      <p>
        <Button class="btn" @click="reset">重填 | Refill</Button>
        <Button class="btn" :loading="submitting" @click="submit">查询 | Query</Button>
        <Button class="btn" @click="goBack">返回 | Return</Button>
      </p>
      <p class="err">{{errorMsg}}</p>
    </template>
    <template v-else>
      <template v-if="info.imgUrl">
        <div class="awardText">
          <p>恭喜您荣获</p>
          <p class="en">Congratulations on the award</p>
          <p>“龙腾之星 • 全球大学生绿色设计大赛 -- 优秀组织奖</p>
          <p class="en">Dragon Star • Global college students green design contest -- Excellent Organization Award</p>
          <p>荣誉证生已生成，请下载：</p>
          <p>please download from this link:</p>  
        </div>
        <div><img class="certImg" :src="getCertImageUrl(info.imgUrl)" alt=""></div>
      </template>
      <p>
        <Button v-if="info.imgUrl" class="btn" @click="downloadCertificate">证书下载 | Certificate Download</Button>
        <Button class="btn" @click="changeStep(1)">返回 | Return</Button>
      </p>
      <p class="err">{{errorMsg}}</p>
    </template>
  </div>
</template>

<script>
import Service from "@/api/service";
import { baseUrl } from "@/config/env";
export default {
  name: "Index",
  data() {
    return {
      step: 1,

      form: {},
      submitting: false,
      errorMsg: "",

      info: {}
    };
  },
  methods: {
    changeStep(i) {
      this.step = i;
      this.errorMsg = "";
    },
    // 查询
    submit() {
      if (!this.form.school) {
        this.errorMsg = "请输入院校名称";
        return;
      }
      this.errorMsg = "";
      this.submitting = true;
      // 提交信息到服务器
      let _this = this;

      Service.getCertificateUrl({
        type: "organization",
        institution: _this.form.school
      })
        .then(res => {
          let errNo = res.data.errorNo;
          if (errNo == 0) {
            _this.info.imgUrl = res.data.data.imgPath;
            _this.info.awardType = res.data.data.award;
            _this.submitting = false;
            _this.changeStep(2);
            if (!_this.info.imgUrl) {
              _this.errorMsg = "未查询到证书信息";
            }
          } else if (errNo == 5550) {
            this.$Modal.info({
              title: "提示",
              content: "未查询到相关信息，请检查输入是否正确。若有疑问请联系组委会010-83681553。",
              okText: "确定"
            });
            _this.submitting = false;
          } else if (errNo == 5552) {
            this.$Modal.info({
              title: "提示",
              content: "查询到多条相关信息，请精确查询条件。若有疑问请联系组委会010-83681553。",
              okText: "确定"
            });
            _this.submitting = false;
          }else if (errNo == 9999) {
            this.$Modal.info({
              title: "提示",
              content: "未查询到相关信息，请检查输入是否正确。若有疑问请联系组委会010-83681553。",
              okText: "确定"
            });
            _this.submitting = false;
          } else {
            this.$Message.error(res.data.errorMessage)
            _this.submitting = false;
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试")
          _this.submitting = false;
        });
    },
    // 重置
    reset() {
      this.form = {};
      this.errorMsg = "";
      this.submitting = false;
    },
    // 返回
    goBack(){
      this.$router.push('/certificate/nav')
    },
    // 下载证书
    download() {
      if (!this.info.pdfUrl) {
        this.errorMsg = "下载内容为空，如有问题请联系大赛负责人";
        return;
      }
      let url = this.info.pdfUrl;
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", ""); // 文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      this.$router.push("/next");
    },
    downloadCertificate() {
      let downloadUrl =
        baseUrl +
        "competition-certificate/download?type=organization" +
        "&institution=" +
        this.form.school;
      window.open(downloadUrl);
      // this.$router.push("/next");
    }
  }
};
</script>
